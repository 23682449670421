import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/alirun/Work/Blockeys/ofi-landing/src/layouts/blogPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "why-it-is-important",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-it-is-important",
        "aria-label": "why it is important permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why it is important`}</h1>
    <p>{`RealT.co is a progressive technology platform that offers investors the ability to purchase fractional real estate powered by blockchain. For around $50, you can purchase one token (or as many as you would like to invest) of a property. Investors are then paid a percentage of the tenant’s rent based on their investment in USDC through Ethereum or xDai network.`}</p>
    <p>{`Opium, based on Ethereum, is a decentralized protocol, hosted through the Polygon network. One of the main use cases of Opium is protection (decentralised insurance).
Currently, all the properties offered on RealT.co are covered by the standard insurance to protect them from real estate disasters like fire and water damage. What is not insured, is the occupancy (monthly rent payment).`}</p>
    <p>{`For example, in the event of a fire, it might take three months for repairs and renovations. The renovation expenses are covered by the property insurance; but if the tenant has to relocate, they do not pay rent during this time. This means rent will not be distributed for this property for those 3 months. The insurance offered through Opium Protocol will continue to distribute rental payments when the tenant doesn’t pay. Protection by Opium ensures RealT member’s cash flows.`}</p>
    <h1 {...{
      "id": "how-it-works",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-it-works",
        "aria-label": "how it works permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How it works`}</h1>
    <h2 {...{
      "id": "i-buyers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#i-buyers",
        "aria-label": "i buyers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`I. Buyers`}</h2>
    <p>{`Buyer goes through several steps `}<a parentName="p" {...{
        "href": "https://app.opium.finance/eth/insurance"
      }}>{`here`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Choose a nominal to protect.`}</strong>{` The amount of rent you expect to receive from your RealT property and want to protect; may be higher or lower than the actual rent you receive now. But based on this nominal you, the premium that you pay will be calculated. In case of no occupancy (and no-rent), you will be compensated proportionally to the nominal.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Transfer a premium to the decentralised smart contract`}</strong>{` and get a tokenised position.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`In case of “no-occupancy”`}</strong>{` during the protection period and lower rent, you will be automatically compensated with the difference from the smart contract. For example, you ensure $100 rent for a month. If there were no tenants for two weeks and RealT distributed only $50, you will automatically get $50 from the smart contract. In case of no tenants for the whole month, you will get $100 from the smart contract.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`It is `}<strong parentName="p">{`essential`}</strong>{` to say that once “no-occupancy” occurred, sellers of protection can not unstake their funds until tenants are back again.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Rental insurance by Opium smart contracts is available the first five days of each month when rent is expected. If rent is not paid by the seventh day of the month, Opium will cover the rent for the month portion and trigger a deposit into the investors wallet equaling the unpaid amount. For example, if a buyer is expecting $20 of rent every month, on the seventh, if the rent is not paid, then they will receive roughly $5, one weeks worth. The following week a similar calculation is made until the end of the month.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "ii-sellers-stakers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ii-sellers-stakers",
        "aria-label": "ii sellers stakers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`II. Sellers (stakers)`}</h2>
    <p>{`Sellers can `}<a parentName="p" {...{
        "href": "https://app.opium.finance/eth/staking"
      }}>{`stake in the decentralised pool`}</a>{` if the expect full occupancy.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`By staking money into the smart contract pool, `}<strong parentName="p">{`you take the risk of “no-occupancy”`}</strong>{`. According to a decentralised optimistic oracle, if there are no tenants (no-rent) in the building, your collateral will be used to compensate buyers of the protection automatically.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Once “no-occupancy” occurred, `}<strong parentName="p">{`sellers of protection can not unstake their funds until there are tenants again`}</strong>{`. They may lose all collateral in the situation that there are no tenants for a long time.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can unstake `}<strong parentName="p">{`every first day of the month`}</strong>{` or transfer your stake (LP position) as a simple ERC-20 token. Your stake (LP position) is accumulating all the buyer premiums of the protection pool.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`The more stakers are in the pool, the more time of “no occupancy” it may cover and the less yield (APR) a staker receive.`}</strong>{` The pool’s maximum size is limited to full-year rent, so the corresponding minimum APR is 10%. If the pool size covers only six months of “no-tenants”, the APR will be 20%.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`So far, the maximum “no occupancy” with RealT properties was three months that makes the mathematics of this decentralised protection fascinating.
In August, the Opium governance forum was buzzing with activity, with several important topics being discussed.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3b7e1907d84a6d5940e3548fb4f26b7/a2510/39.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQD/2gAMAwEAAhADEAAAAeRlTmpTW6EYFf8A/8QAGRAAAgMBAAAAAAAAAAAAAAAAACEQEUEB/9oACAEBAAEFAsrkYrEKP//EABURAQEAAAAAAAAAAAAAAAAAABAC/9oACAEDAQE/AST/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BI//EABkQAAIDAQAAAAAAAAAAAAAAABBBAAEhMf/aAAgBAQAGPwLsrSyx/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAIUExEP/aAAgBAQABPyEANrKhahpXW+ABn3JyxsUFC+5OxjYp/9oADAMBAAIAAwAAABBQKIL/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAwEBPxBOkG7n/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/EDBF/8QAHRABAAMAAwADAAAAAAAAAAAAAQARITFBoRBRcf/aAAgBAQABPxDSoAU7fcq6EG9K7BEVuYU8cexNtqmq8qfiHEeGPY3zWNV8P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "39",
            "title": "39",
            "src": "/static/c3b7e1907d84a6d5940e3548fb4f26b7/a2510/39.jpg",
            "srcSet": ["/static/c3b7e1907d84a6d5940e3548fb4f26b7/f93b5/39.jpg 300w", "/static/c3b7e1907d84a6d5940e3548fb4f26b7/b4294/39.jpg 600w", "/static/c3b7e1907d84a6d5940e3548fb4f26b7/a2510/39.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1 {...{
      "id": "about-opium--realt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#about-opium--realt",
        "aria-label": "about opium  realt permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About Opium & RealT`}</h1>
    <p>{`Core contributors of both protocols were working significant time together to develop the prototype of DeFi protection. Traditional insurance for “no occupancy” is costly and may be challenging to structure legally in different countries. Still, at the same time, “no occupancy” is a transparent event that can be validated using one of the optimistic oracles (Gnosis SafeSnap, UMA, Opium).`}</p>
    <p>{`With both communities’ participation, the first simple protection primitive was developed on top of the Opium Protocol. After user feedback, it can be improved to have a better user experience (automatic prolongations etc.) and even be an option that is included as a checkbox on the RealT platform.`}</p>
    <p>{`It was decided during RealT bi-monthly Community Calls that protection would be available to all RealT users (subject to KYC and whitelist on-chain).`}</p>
    <p>{`To learn more or join the RealT community subscribe to Telegram and follow on twitter: `}<a parentName="p" {...{
        "href": "https://realt.co"
      }}>{`Website`}</a>{` | `}<a parentName="p" {...{
        "href": "https://twitter.com/realtplatform"
      }}>{`Twitter`}</a>{` | `}<a parentName="p" {...{
        "href": "https://t.me/RealT_Platform"
      }}>{`Telegram`}</a></p>
    <p>{`Stay tuned and join Opium Team as a part-time or full-time contributor:`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://docs.opium.network"
        }}>{`Explore Opium Protocol`}</a></strong>{`, get last news on the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://t.me/OpiumFinance"
        }}>{`Telegram News Channel`}</a></strong>{` or `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://opium.finance"
        }}>{`try out Opium Finance`}</a></strong>{`. Stay informed and `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://twitter.com/Opium_Network"
        }}>{`follow Opium.Team on Twitter`}</a></strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      